@import './variables.scss';

.nivel-container {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #fff;
}

.nivel-container-1 {
  background-color: #f08080;
}

.nivel-container-2 {
  background-color: #adadad;
}

.nivel-container-3 {
  background-color: #ffcd09;
}

.react-datepicker-wrapper {
  width: 100%;
}

.no-decoration:hover {
  text-decoration: none;
  cursor: pointer;
}

.no-decoration:hover .decorated {
  text-decoration: underline;
}

.scale-animated:hover {
  background-color: #fafafa;
  transition: all .1s ease-in-out;
  transform: scale(1.008);
}

.user-link {
  text-decoration: none;
  color: inherit;
}

.user-link .user-link-container .options {
  transition: max-height 0.5s ease-in-out;
  max-height: 0;
  overflow: hidden;
}

.user-link:hover .user-link-container .options {
  max-height: 1000px;
}

.row-reverse {
  flex-direction: row-reverse;
}

.tab-item a:focus,
.breadcrumb-item a:focus {
  box-shadow: none;
}

a.user-option {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a.user-option:hover div {
  background-color: $primary-light-color;
}

.card,
.panel.panel-custom {
  border: 0;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, .15);
  height: 100%;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.nav-items a {
  text-decoration: none;
  color: #666;
  font-weight: 500;
}

.nav-items .link-row {
  padding-top: .3rem;
  padding-bottom: .3rem;
}

.nav-items .link-row:hover,
.nav-items a.active .link-row {
  background-color: $primary-light-color;
}

.react-confirm-alert-overlay {
  z-index: 999;
}

.header-sticky {
  position: sticky;
  top: 0;
}

.full-vh {
  min-height: 100vh;
}

.pulse {
  animation: pulse 1.3s infinite;
}

.pre {
  white-space: pre-wrap;
}

.popover-only-click {
  &:hover {
    .popover-container {
      opacity: 0;
    }
  }

  &.active {
    .popover-container {
      display: block;
      opacity: 1;
      transform: translate(-50%, -100%) scale(1);
    }
  }
}

.only-print {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }

  .only-print {
    display: block;
  }
}