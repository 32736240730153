@keyframes bounce-in {
  0%{
    opacity: .3;
    -webkit-transform: scale(.3);
    transform: scale(.3);
  }
  50%{
    opacity: 1;
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
  70%{
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100%{
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
	0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
	}

	50% {
    opacity: .5;
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
	}

	100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
	}
}